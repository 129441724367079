.register {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
  }
  
  .wrapper {
    border: 1px solid #ccc;
    padding: 20px;
    width: 400px; /* Adjust the width as desired */
    height: 400px; /* Adjust the height as desired */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  label {
    margin-bottom: 10px;
}
  
  input {
    padding: 10px;
    margin-bottom: 10px;
    width: 300px;
  }
  
.regBtn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 15px;
  }
  
  .register .regBtn:hover {
    background-color: #45a049;
  }
  