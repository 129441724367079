.display {
    text-align: center;
    padding: 20px;
  }
  
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .receipeDisplay {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .receipeDisplay h2 {
    margin-top: 0;
  }
  
  .receipeDisplay img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .receipeDisplay h3 {
    margin: 0;
    color: #888;
  }
  
  .receipeDisplay p {
    margin-top: 10px;
    font-weight: bold;
  }

  .disBtn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 15px;
  }
  
  .disBtn:hover {
    background-color: #45a049;
  }

  .searchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .receipeSearch {
    display: flex;
    align-items: center;
  }
  
  .receipeSearch input[type="text"] {
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 8px;
    width: 300px;
    margin-right: 8px;
  }
  
  .receipeSearch button[type="button"] {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  
  .receipeSearch button[type="button"]:hover {
    background-color: #45a049;
  }
  
  .receipeSearch input[type="text"]::placeholder {
    color: #999;
  }
  
  .receipeSearch input[type="text"]:focus {
    outline: none;
    border-color: #4CAF50;
  }
  